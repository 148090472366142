/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * 
 */
var Viewport = (function () {
    'use strict';
    /* ----- Declare Valiables ---------------------------------------------------------------------------------------- */
    var
        meta = {},
        $metaTag = $('meta[name=viewport]'),
        ua = navigator.userAgent.toLowerCase(),
        isAndroid = ua.indexOf("android") > -1;

    /* ----- Init Viewport -------------------------------------------------------------------------------------------- */
    function Init() {
        if (isAndroid) {
            parse();
            set();
        }
    }

    /* ----- Parse Viewport ------------------------------------------------------------------------------------------- */
    function parse() {
        var arr = $metaTag.attr('content').split(',');
        for (var i in arr) {
            var parts = arr[i].trim().split('=');
            meta[parts[0]] = parts[1];
        }
    }

    /* ----- Set Viewport --------------------------------------------------------------------------------------------- */
    function set() {
        var
            availHeight = screen.availHeight,
            arrNew = [];
        if (!'height' in meta || meta['height'] !== availHeight) {
            meta['height'] = String(availHeight);
            for (var i in meta) {
                arrNew.push(i + '=' + meta[i]);
            }
            $metaTag.attr('content', arrNew.join(','));
        }
    }

    /* ----- Bind Events ---------------------------------------------------------------------------------------------- */
    if (isAndroid) {
        $(window).on('load resize orientationchange', Init);
    }
    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init
    };
})();