/*
 Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential

 */
var Native = (function () {
    'use strict';

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
    }

    /* ----- Logger --------------------------------------------------------------------------------------------------- */
    function writeLog(entry) {
        $.post('api/', {
            do: 'jsNativeLog',
            entry: entry
        });
    }

    function layoutSwap(id) {
        $(function () {
            writeLog('layoutSwap -> ' + id);
            Layout.swap(id);
        });
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        writeLog: writeLog,
        layoutSwap: layoutSwap
    };
})();